import { put } from 'redux-saga/effects'
import { push } from 'connected-react-router'

export function * toLocation (_redirect, _condition, data) {
  if (_redirect) {
    if (
      !_condition ||
      (typeof _condition === 'function' && _condition(data))
    ) {
      yield put(push(_redirect))
    }
  }
}
