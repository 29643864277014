import Regular from './DomaineSansText-Regular.woff'
import Medium from './DomaineSansText-Medium.woff'
import Light from './DomaineSansText-Light.woff'
import LightI from './DomaineSansText-LightItalic.woff'
import Bold from './DomaineSansText-Black.woff'
import BoldI from './DomaineSansText-BlackItalic.woff'

const fontName = 'Domaine Sans Text'

export const fontTypes = []

fontTypes.push({
  fontFamily: `'${fontName}'`,
  src: `local('${fontName}'), url(${Regular}) format('woff')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontWeight: 600,
  src: `local('${fontName}'), url(${Medium}) format('woff')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontWeight: 300,
  src: `local('${fontName}'), url(${Light}) format('woff')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontStyle: 'oblique',
  fontWeight: 300,
  src: `local('${fontName}'), url(${LightI}) format('woff')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontWeight: 700,
  src: `local('${fontName}'), url(${Bold}) format('woff')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontStyle: 'oblique',
  fontWeight: 700,
  src: `local('${fontName}'), url(${BoldI}) format('woff')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontWeight: 800,
  src: `local('${fontName}'), url(${Bold}) format('woff')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontStyle: 'oblique',
  fontWeight: 800,
  src: `local('${fontName}'), url(${BoldI}) format('woff')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontWeight: 900,
  src: `local('${fontName}'), url(${Bold}) format('woff')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontStyle: 'oblique',
  fontWeight: 900,
  src: `local('${fontName}'), url(${BoldI}) format('woff')`
})
