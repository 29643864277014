export function updateQuery ({ query } = {}) {
  return {
    type: 'store/UPDATE_QUERY',
    payload: { query }
  }
}

export function updateSearchType ({ type } = {}) {
  return {
    type: 'store/UPDATE_SEARCH_TYPE',
    payload: { type }
  }
}

export function searchStoresNearby ({ campaignId, consumerCpf, geolocation } = {}) {
  return {
    type: 'store/SEARCH_STORES_NEARBY',
    payload: { campaignId, consumerCpf, geolocation }
  }
}

export function updateStoresNearby ({ stores = [], error } = {}) {
  return {
    type: 'store/UPDATE_STORES_NEARBY',
    payload: { stores, error }
  }
}

export function searchStoresByText ({ campaignId, consumerCpf, search } = {}) {
  return {
    type: 'store/SEARCH_STORES_BY_TEXT',
    payload: { campaignId, consumerCpf, search }
  }
}

export function updateStoresByText ({ stores = [], error } = {}) {
  return {
    type: 'store/UPDATE_STORES_BY_TEXT',
    payload: { stores, error }
  }
}

export function pickStore ({ store = {} } = {}) {
  return {
    type: 'store/PICK_STORE',
    payload: { store }
  }
}

export function getStoreDates ({ campaignId, storeCode } = {}) {
  return {
    type: 'store/GET_STORE_DATES',
    payload: { campaignId, storeCode }
  }
}

export function updateStoreDates ({ dates = [], error } = {}) {
  return {
    type: 'store/UPDATE_STORE_DATES',
    payload: { dates, error }
  }
}

export function setStoreDate ({ date } = {}) {
  return {
    type: 'store/SET_STORE_DATE',
    payload: { date }
  }
}

export function getStoreTimes ({ campaignId, storeCode } = {}) {
  return {
    type: 'store/GET_STORE_TIMES',
    payload: { campaignId, storeCode }
  }
}

export function updateStoreTimes ({ availableTimes = [], error } = {}) {
  return {
    type: 'store/UPDATE_STORE_TIMES',
    payload: { availableTimes, error }
  }
}

export function setStoreTimes ({ selectedTimes = [] } = {}) {
  return {
    type: 'store/SET_STORE_TIMES',
    payload: { selectedTimes }
  }
}

export function bookingVoucher ({ campaignId, consumerCpf, storeCode, voucherCode, batch, timeChosenRedemption = [], attempt = 1, _redirect, _condition } = {}) {
  return {
    type: 'store/BOOKING_VOUCHER',
    payload: { campaignId, consumerCpf, storeCode, voucherCode, batch, timeChosenRedemption, attempt, _redirect, _condition }
  }
}

export function updateBookingVoucher ({ error } = {}) {
  return {
    type: 'store/UPDATE_BOOKING_VOUCHER',
    payload: { error }
  }
}

export function storeReminder ({ campaignId, storeCode, consumerCpf } = {}) {
  return {
    type: 'store/STORE_REMINDER',
    payload: { campaignId, storeCode, consumerCpf }
  }
}

export function updateReminder ({ error } = {}) {
  return {
    type: 'store/UPDATE_REMINDER',
    payload: { error }
  }
}

export function resetSearchs () {
  return {
    type: 'store/RESET_SEARCHS'
  }
}
