export function bookingVoucher ({ metadata, campaignId, consumerCpf, voucherCode, offerId, partnerId, mechanic, provider, attempt = 1 } = {}) {
  return {
    type: 'gifthub/BOOKING_VOUCHER',
    payload: { metadata, campaignId, consumerCpf, voucherCode, offerId, partnerId, mechanic, provider, attempt }
  }
}

export function updateBookingVoucher ({ error, offerChoice = {} } = {}) {
  return {
    type: 'gifthub/UPDATE_BOOKING_VOUCHER',
    payload: { error, offerChoice }
  }
}
