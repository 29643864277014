import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import fullpageLoaderReducers from './fullpage-loader/reducers'
import campaignReducers from './campaign/reducers'
import voucherReducers from './voucher/reducers'
import resellerReducers from './reseller/reducers'
import quizReducers from './quiz/reducers'
import storeReducers from './store/reducers'
import raffleReducers from './raffle/reducers'
import geolocationReducers from './geolocation/reducers'
import resetDateReducers from './reset-date/reducers'
import configReducers from './config/reducers'
import bonusOffersReducers from './bonus-offers/reducers'
import giftHubReducers from './gift-hub/reducers'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  fullpageLoaderReducers,
  campaignReducers,
  voucherReducers,
  resellerReducers,
  quizReducers,
  storeReducers,
  raffleReducers,
  geolocationReducers,
  resetDateReducers,
  configReducers,
  bonusOffersReducers,
  giftHubReducers
})

export default createRootReducer
