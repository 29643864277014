import { takeLatest, put, all, call } from 'redux-saga/effects'

import api from '~/services/api'

import {
  updateCampaignConfig
} from './actions'

export function * getCampaignConfig ({ payload }) {
  try {
    const { campaignId } = payload

    if (!campaignId) throw new Error('Ocorreu um erro ao processar a requisição')

    const url = `/campaign/${campaignId}/consumerEntryPoint`

    const response = yield call(
      api.get,
      url
    )

    if (response.status === 200) {
      if ((response.data.success || (!response.data.success && response.data?.campaignLockReasons)) && response.data?.data && response.data.data.length > 0) {
        yield put(updateCampaignConfig({
          campaign: {
            ...response.data.data[0],
            ...(response.data?.campaignLockReasons ? { campaignLockReasons: response.data.campaignLockReasons } : {})
          }
        }))
      } else {
        yield put(updateCampaignConfig({ error: response?.data?.errors[0] || response.data.message }))
      }
    } else {
      yield put(updateCampaignConfig({ error: 'Ocorreu um erro ao processar a requisição' }))
    }
  } catch (error) {
    yield put(updateCampaignConfig({ error: error.message }))
  }
}

export default all([
  takeLatest('campaign/GET_CAMPAIGN_CONFIG', getCampaignConfig)
])
