import React from 'react'
import { useParams } from 'react-router-dom'

import Dynamic from '~/pages/dynamic'

export default (props) => {
  const { campaignId, slug } = useParams()

  const [Component, step] = Dynamic({ campaignId, slug })

  return <Component step={step} />
}
