import produce from 'immer'

import * as ACTs from './actions'

export const Types = {}

Object.values(ACTs).forEach(fn => {
  const type = fn().type

  Types[type.split('/').pop()] = type
})

const INITIAL_STATE = {
  loadings: {
    searchStoresByText: false,
    searchStoresNearby: false,
    getStoreDates: false,
    getStoreTimes: false,
    bookingVoucher: false,
    storeReminder: false
  },
  errors: {
    searchStoresByText: false,
    searchStoresNearby: false,
    getStoreDates: false,
    getStoreTimes: false,
    bookingVoucher: false,
    storeReminder: false
  },
  attempts: {
    searchStoresByText: 0,
    searchStoresNearby: 0,
    getStoreDates: 0,
    getStoreTimes: 0,
    storeReminder: 0
  },
  searchType: 'text',
  stores: [],
  store: {},
  query: '',
  selectedDate: false,
  selectedTimes: []
}

export default function storeReducers (state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.UPDATE_SEARCH_TYPE: {
        draft.searchType = action.payload?.type || 'text'
        break
      }
      case Types.UPDATE_QUERY: {
        draft.loadings.searchStoresByText = false
        draft.errors.searchStoresByText = false
        draft.attempts.searchStoresByText = 0
        draft.stores = []
        draft.query = action.payload?.query || ''
        break
      }
      case Types.SEARCH_STORES_BY_TEXT: {
        draft.loadings.searchStoresByText = true
        draft.errors.searchStoresByText = false
        draft.attempts.searchStoresByText = 0
        draft.stores = []
        break
      }
      case Types.UPDATE_STORES_BY_TEXT: {
        draft.loadings.searchStoresByText = false
        draft.attempts.searchStoresByText = 1
        draft.errors.searchStoresByText = action.payload?.error || false

        draft.stores = action.payload?.stores || []
        break
      }
      case Types.SEARCH_STORES_NEARBY: {
        draft.loadings.searchStoresNearby = true
        draft.errors.searchStoresNearby = false
        draft.attempts.searchStoresNearby = 0
        break
      }
      case Types.UPDATE_STORES_NEARBY: {
        draft.loadings.searchStoresNearby = false
        draft.attempts.searchStoresNearby = 1
        draft.errors.searchStoresNearby = action.payload?.error || false

        draft.stores = action.payload?.stores || []
        break
      }
      case Types.FIND_ADDRESS_BY_ZIPCODE: {
        draft.loadings.findAdressByZipCode = true
        draft.errors.addressFind = false
        break
      }
      case Types.UPDATE_ADDRESS: {
        draft.loadings.findAdressByZipCode = false
        draft.errors.addressFind = action.payload?.error || false

        draft.address = action.payload?.address || {}
        break
      }
      case Types.FIND_ADDRESS_BY_DATA: {
        draft.loadings.findAdressByData = true
        draft.errors.addressFind = false
        break
      }
      case Types.UPDATE_GEOLOCATION: {
        draft.loadings.findAdressByData = false
        draft.errors.addressFind = action.payload?.error || false

        draft.geolocation = action.payload?.geolocation || {}
        break
      }
      case Types.GET_CITIES: {
        draft.loadings.getCities = true
        draft.errors.getCities = false
        break
      }
      case Types.UPDATE_CITIES: {
        draft.loadings.getCities = false
        draft.errors.getCities = action.payload?.error || false

        draft.cities = action.payload?.cities || []
        break
      }
      case Types.UPDATE_ADDRESS_FIELD: {
        if (action?.payload?.field) {
          const nAddress = { ...state.address }

          if (nAddress[action?.payload?.field]) {
            delete nAddress[action?.payload?.field]
          }

          if (action?.payload?.value) {
            nAddress[action.payload.field] = action.payload.value
          }

          draft.address = nAddress
        }
        break
      }
      case Types.PICK_STORE: {
        draft.store = {}

        if (Object.entries(action.payload?.store).length > 0) {
          draft.store = { ...state.store, ...action.payload.store }
        }
        break
      }
      case Types.BOOKING_VOUCHER: {
        draft.loadings.bookingVoucher = true
        draft.errors.bookingVoucher = false
        break
      }
      case Types.UPDATE_BOOKING_VOUCHER: {
        draft.loadings.bookingVoucher = false
        draft.errors.bookingVoucher = action.payload?.error || false

        if (action.payload?.store) {
          draft.store = { ...state.store, ...action.payload?.store }
        }
        break
      }
      case Types.GET_STORE_DATES: {
        draft.loadings.getStoreDates = true
        draft.errors.getStoreDates = false
        draft.attempts.searchStoresByText = 0
        break
      }
      case Types.UPDATE_STORE_DATES: {
        draft.loadings.getStoreDates = false
        draft.errors.getStoreDates = action.payload?.error || false
        draft.attempts.searchStoresByText = 1

        if (action.payload?.dates) {
          draft.store = { ...state.store, ...{ storeAvailableDates: action.payload?.dates } }
        }
        break
      }
      case Types.SET_STORE_DATE: {
        draft.attempts.getStoreTimes = 0
        draft.selectedDate = action.payload?.date
        break
      }
      case Types.GET_STORE_TIMES: {
        draft.loadings.getStoreTimes = true
        draft.errors.getStoreTimes = false
        draft.attempts.getStoreTimes = 0
        break
      }
      case Types.UPDATE_STORE_TIMES: {
        draft.loadings.getStoreTimes = false
        draft.errors.getStoreTimes = action.payload?.error || false
        draft.attempts.getStoreTimes = 1

        if (action.payload?.availableTimes) {
          const storeAvailableTimes = action.payload?.availableTimes.sort((a, b) => {
            return (parseInt(a.initial.replace(/:/g, '')) > parseInt(b.initial.replace(/:/g, '')) ? 1 : -1)
          })

          draft.store = { ...state.store, ...{ storeAvailableTimes } }
        }
        break
      }
      case Types.SET_STORE_TIMES: {
        draft.selectedTimes = action.payload?.selectedTimes || []
        break
      }
      case Types.RESET_SEARCHS: {
        draft.store = {}
        draft.stores = []
        draft.loadings.searchStoresNearby = false
        draft.attempts.searchStoresNearby = 0
        draft.errors.searchStoresNearby = false
        draft.loadings.searchStoresByText = false
        draft.attempts.searchStoresByText = 0
        draft.errors.searchStoresByText = false
        draft.loadings.storeReminder = false
        draft.errors.storeReminder = false
        draft.attempts.storeReminder = 0
        break
      }
      case Types.STORE_REMINDER: {
        draft.loadings.storeReminder = true
        draft.errors.storeReminder = false
        draft.attempts.storeReminder = 0
        break
      }
      case Types.UPDATE_REMINDER: {
        draft.loadings.storeReminder = false

        if (action.payload?.error) {
          draft.attempts.storeReminder = 1
          draft.errors.storeReminder = action.payload?.error
        } else {
          draft.store = { ...state.store, ...{ reminderEnabled: true } }
          draft.attempts.storeReminder = 1
          draft.errors.storeReminder = action.payload?.error
        }
        break
      }
      default:
    }
  })
}
