import Regular from './ITCAvantGardeStdBk.woff'
import RegularCN from './ITCAvantGardeStdBkCn.woff'
import ItalicCN from './ITCAvantGardeStdBkCnObl.woff'
import ItalicBk from './ITCAvantGardeStdBkObl.woff'
import MediumBold from './ITCAvantGardeStdBold.woff'
import BoldCN from './ITCAvantGardeStdBoldCn.woff'
import BoldItalic from './ITCAvantGardeStdBoldCnObl.woff'
import MediumBoldItalic from './ITCAvantGardeStdBoldObl.woff'
import BKBold from './ITCAvantGardeStdDemi.woff'

const fontName = 'ITC Avant Garde'

export const fontTypes = []

fontTypes.push({
  fontFamily: `'${fontName}'`,
  src: `local('${fontName}'), url(${Regular}) format('woff')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  src: `local('${fontName}'), url(${RegularCN}) format('woff')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  src: `local('${fontName}'), url(${ItalicCN}) format('woff')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  src: `local('${fontName}'), url(${ItalicBk}) format('woff')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontWeight: 700,
  src: `local('${fontName}'), url(${MediumBold}) format('woff')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontWeight: 700,
  src: `local('${fontName}'), url(${BoldCN}) format('woff')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontWeight: 700,
  src: `local('${fontName}'), url(${BoldItalic}) format('woff')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontWeight: 500,
  src: `local('${fontName}'), url(${MediumBoldItalic}) format('woff')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  src: `local('${fontName}'), url(${BKBold}) format('woff')`
})
