import Regular from './Roboto-Regular.woff'
import RegularI from './Roboto-Italic.woff'
import Bold from './Roboto-Bold.woff'
import BoldI from './Roboto-BoldItalic.woff'

const fontName = 'Roboto'

export const fontTypes = []

fontTypes.push({
  fontFamily: `'${fontName}'`,
  src: `local('${fontName}'), url(${Regular}) format('woff')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontStyle: 'oblique',
  src: `local('${fontName}'), url(${RegularI}) format('woff')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontWeight: 700,
  src: `local('${fontName}'), url(${Bold}) format('woff')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontStyle: 'oblique',
  fontWeight: 700,
  src: `local('${fontName}'), url(${BoldI}) format('woff')`
})
