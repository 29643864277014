import createTheme from './createTheme'
import { fontTypes as Effra } from '~/assets/fonts/effra'
import { fontTypes as DomaineSansText } from '~/assets/fonts/domaine-sans-text'

const titleFonts = ['"Domaine Sans Text"', 'Times new roman', 'Serif'].join(
  ', '
)

const baseFont = ['"Effra"', 'Arial', 'sans-serif'].join(', ')

const primaryColor = '#1074AB'
const secondaryColor = '#3D9459'
const errorColor = '#DE1616'
const warningColor = '#FF9700'

export default createTheme({
  primaryColor,
  secondaryColor,
  errorColor,
  warningColor,
  titleFonts,
  baseFont,
  ExternalFonts: [...Effra, ...DomaineSansText]
})
