export const WhatsappShare = (text) => {
  const urlParams = new URLSearchParams()
  urlParams.append('text', text)

  return `https://api.whatsapp.com/send?${urlParams.toString()}`
}

export const getLink = (campaignId, medium) => {
  const tags = {
    utm_source: 'AFcompartilhar',
    utm_medium: medium,
    utm_campaign: campaignId,
    utm_id: campaignId
  }

  return `https://${document.location.hostname}/${campaignId}?${new URLSearchParams(tags).toString()}`
}

/**
 * Constructs a URL for a campaign with the given ID and action.
 *
 * @param {string} campaignId - The ID of the campaign.
 * @param {string} action - The action to be appended to the campaign ID.
 *                          Possible values: "baixeagora", "copiarlink", "whatsapp".
 * @returns {string} The constructed URL.
 */
export const getOneLink = (campaignId, action) => {
  return `https://boticario.onelink.me/rVs3/${campaignId.replace(/-/g, '')}${action}`
}
