
export const hasMocky = (conf) => {
  const { url, data } = conf

  switch (true) {
    case (/consumerBirthday=/i.test(url)):
      return 'consumer.json'
    case (url.includes('/consumer?step=CADASTRO')):
      return 'consumerStepCadastro.json'
    case (url.includes('/channel/store')):
      return 'store.json'
    case (url.includes('/channel/ecomm')):
      return 'ecomm.json'
    case (url.includes('/channel/whatsappStore')):
      return 'whatsappStore.json'
    case (/searchByText?/i.test(url)):
      return 'searchByText.json'
    case (url.includes('/findaddress?zipCode=')):
      return 'findAddress.json'
    case (url.includes('/geolocation/findcoordinates?')):
      return 'findCoordinates.json'
    case (url.includes('/resellers/nearby?')):
      return 'nearbyResellers.json'
    case (url.includes('/redeemDays')):
      return 'redeemDays.json'
    case (/availability?/i.test(url)):
      return 'consumerStoresAvailability.json'
    case (url.includes('quiz?consumerCpf')):
      return 'quiz.json'
    case (url.includes('/booking')):
      return 'booking.json'
    case (url.includes('/reminder')):
      return 'reminder.json'
    case (url.includes('/channel/reseller')):
      return 'reseller.json'
    case (url.includes('/quiz') && !url.includes('consumerCpf')): {
      if (data?.questionCode === 100) {
        return 'resultQuiz.json'
      }
      return 'quizStep.json'
    }
    default:
      return ''
  }
}
