import { takeLatest, put, all, call } from 'redux-saga/effects'

import api from '~/services/api'
import { removeAccents } from '~/helpers/strings'

import {
  Types,
  updateBonusOffers,
  updateFilteredOffers
} from './actions'
import { compareByKind } from '~/helpers/compareByKind'

const PARTER_NAMES = {
  OBOTICARIO: 'o_boticario'
}

function sortOffersData (dataToSort) {
  return dataToSort.map(category => ({
    ...category,
    subCategories: [...category.subCategories].sort((a, b) => compareByKind(a, b))
  }))
}

export function * getBonusOffers ({ payload = {} }) {
  const {
    campaignId = '',
    state,
    city,
    sort = 'asc',
    passwordTier
  } = payload

  try {
    const url = `/campaign/${campaignId}/partners/offers`

    const response = yield call(
      api.get,
      url,
      {
        params: {
          ...(state !== '' ? { offerState: state } : {}),
          ...(city !== '' ? { offerCity: city } : {}),
          ...(passwordTier !== '' ? { offerTier: passwordTier.toLowerCase() } : {})
        }
      }
    )

    if (response.status === 200 && response?.data?.success && Array.isArray(response?.data?.data)) {
      const sortMethods = {
        asc: ({ category: c1 }, { category: c2 }) => (c1 > c2) ? 1 : ((c2 > c1) ? -1 : 0),
        desc: ({ category: c2 }, { category: c1 }) => (c1 > c2) ? 1 : ((c2 > c1) ? -1 : 0)
      }

      const sortedData = response.data.data.sort(sortMethods[sort] || sortMethods.asc)

      sortedData.forEach((offer) => {
        offer.subCategories = sortOffersByPriority(offer.subCategories)
      })

      yield put(updateBonusOffers({ bonusOffers: sortOffersData(sortedData) }))
      yield put(updateFilteredOffers({ filteredOffers: sortOffersData(sortedData) }))
    } else {
      yield put(updateBonusOffers({ error: 'Ocorreu um erro ao processar a requisição' }))
    }
  } catch (error) {
    yield put(updateBonusOffers({ error: error.message }))
  }
}

function sortOffersByPriority (offerList) {
  const offersToComeAfter = []
  const offersToComeBefore = []

  offerList.forEach((offer) => {
    if (removeAccents(offer.partnerName).split(' ').join('_') === PARTER_NAMES.OBOTICARIO) {
      offersToComeBefore.push(offer)
    } else {
      offersToComeAfter.push(offer)
    }
  })

  return [...offersToComeBefore, ...offersToComeAfter]
}

export default all([
  takeLatest(Types.getBonusOffers, getBonusOffers)
])
