import produce from 'immer'
import { leaveOnlyNumbers } from '~/helpers/strings'

import * as ACTs from './actions'

export const Types = {}

Object.values(ACTs).forEach(fn => {
  const type = fn().type

  Types[type.split('/').pop()] = type
})

const INITIAL_STATE = {
  loadings: {
    getVoucher: false,
    setVoucher: false,
    setChannel: false
  },
  errors: {
    getVoucher: false,
    setVoucher: false,
    setChannel: false
  },
  voucher: {},
  channel: {},
  consumer: {},
  eligibleConsumer: true
}

const treatFields = (voucher) => {
  const voucherTreated = { ...voucher }

  if (voucherTreated?.consumerCpf) {
    voucherTreated.consumerCpf = leaveOnlyNumbers(voucherTreated.consumerCpf)
  }

  if (voucherTreated?.consumerBirthday) {
    if (voucherTreated.consumerBirthday.includes('/')) {
      voucherTreated.consumerBirthday = voucherTreated.consumerBirthday.split('/').reverse().join('-')
    } else {
      voucherTreated.consumerBirthday = voucherTreated.consumerBirthday.split('-').reverse().join('/')
    }
  }

  return voucherTreated
}

export default function voucherReducers (state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_VOUCHER: {
        draft.loadings.getVoucher = true
        draft.errors.getVoucher = false
        break
      }
      case Types.UPDATE_VOUCHER_VERIFY: {
        draft.loadings.getVoucher = false
        draft.errors.getVoucher = action?.payload?.error || false

        if (action?.payload?.voucher) {
          draft.voucher = action.payload.voucher
          draft.consumer = treatFields(action.payload.voucher)
        }
        break
      }
      case Types.SET_VOUCHER: {
        draft.loadings.setVoucher = true
        draft.errors.setVoucher = false
        draft.channel = {}

        action.payload.consumer = treatFields(action.payload.consumer)
        break
      }
      case Types.UPDATE_VOUCHER: {
        draft.loadings.setVoucher = false
        draft.errors.setVoucher = action?.payload?.error || false

        if (action?.payload?.voucher) {
          draft.voucher = { ...state.voucher, ...action.payload.voucher }

          const treated = treatFields(action.payload.voucher)
          draft.consumer = { ...state.consumer, ...treated }
        }
        break
      }
      case Types.SET_CHANNEL: {
        draft.loadings.setChannel = true
        draft.errors.setChannel = false
        break
      }
      case Types.UPDATE_CHANNEL: {
        draft.loadings.setChannel = false
        draft.errors.setChannel = action?.payload?.error || false

        if (action?.payload?.channel) {
          draft.channel = action.payload.channel
        }
        break
      }
      case Types.UPDATE_CONSUMER: {
        if (action?.payload?.field) {
          const nConsumer = { ...state.consumer }

          if (nConsumer[action?.payload?.field]) {
            delete nConsumer[action?.payload?.field]
          }

          if (action?.payload?.value) {
            nConsumer[action.payload.field] = action.payload.value
          }

          draft.consumer = nConsumer
        }
        break
      }
      case Types.RESET_VOUCHER: {
        draft.loadings.getVoucher = false
        draft.errors.getVoucher = false
        draft.voucher = {}
        draft.consumer = {}
        draft.loadings.setChannel = false
        draft.errors.setChannel = false
        draft.channel = {}
        break
      }
      case Types.ELIGIBLE_CONSUMER: {
        draft.eligibleConsumer = action.payload.eligibleConsumer
        break
      }
      default:
    }
  })
}
