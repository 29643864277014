import Regular from './effra_std_rg-webfont.woff2'
import Medium from './effra_std_md-webfont.woff2'
import MediumI from './effra_std_mdit-webfont.woff2'
import Bold from './effra_std_bd-webfont.woff2'
import BoldI from './effra_std_bdit-webfont.woff2'
import Heavy from './effra_std_he-webfont.woff2'

const fontName = 'Effra'

export const fontTypes = []

fontTypes.push({
  fontFamily: `'${fontName}'`,
  src: `local('${fontName}'), url(${Regular}) format('woff2')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontWeight: 600,
  src: `local('${fontName}'), url(${Medium}) format('woff2')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontStyle: 'oblique',
  fontWeight: 600,
  src: `local('${fontName}'), url(${MediumI}) format('woff2')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontWeight: 700,
  src: `local('${fontName}'), url(${Bold}) format('woff2')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontStyle: 'oblique',
  fontWeight: 700,
  src: `local('${fontName}'), url(${BoldI}) format('woff2')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontWeight: 800,
  src: `local('${fontName}'), url(${Heavy}) format('woff2')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontWeight: 900,
  src: `local('${fontName}'), url(${Heavy}) format('woff2')`
})
