export const Types = {
  getBonusOffers: 'bonusOffers/GET_BONUS_OFFERS',
  updateBonusOffers: 'bonusOffers/UPDATE_BONUS_OFFERS',
  updateFilteredOffers: 'bonusOffers/updateFilteredOffers',
  updateFilters: 'bonusOffers/updateFilters',
  updateQuery: 'bonusOffers/updateQuery',
  reset: 'bonusOffers/RESET'
}

export function getBonusOffers ({ campaignId, state, city, passwordTier, sort = 'asc' } = {}) {
  return {
    type: Types.getBonusOffers,
    payload: { campaignId, state, city, passwordTier, sort }
  }
}

export function updateBonusOffers ({ bonusOffers, error = '' } = {}) {
  return {
    type: Types.updateBonusOffers,
    payload: { bonusOffers, error }
  }
}

export function updateFilteredOffers ({ filteredOffers = [] } = {}) {
  return {
    type: Types.updateFilteredOffers,
    payload: { filteredOffers }
  }
}

export function updateFilters ({ filters } = {}) {
  return {
    type: Types.updateFilters,
    payload: { filters }
  }
}

export function updateQuery ({ query } = {}) {
  return {
    type: Types.updateQuery,
    payload: { query }
  }
}
