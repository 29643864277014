import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import useTracking from '~/services/tracking'

import { Box, Button, Dialog, Grid, Grow, IconButton, Paper, Typography, useMediaQuery } from '@material-ui/core'

import FileCopyIcon from '@material-ui/icons/FileCopy'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import CloseIcon from '@material-ui/icons/Close'
import ShareIcon from '@material-ui/icons/Share'

import ShareButton from './button'

import { WhatsappShare, getLink, getOneLink } from '~/helpers/shares'

const ModalMgm = ({ open = false, showOnlyIcon = false, shareButtonLabel }) => {
  const classes = useStyles()
  const theme = useTheme()
  const tracking = useTracking()

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [copy, setCopy] = useState(false)
  const [openShare, setOpenShare] = useState(open)

  const {
    prms,
    campaignId,
    createdThrough,
    layout: {
      BOAS_VINDAS_IMAGEM_CAMPANHA,
      MGM_HOME_COMPARTILHAMENTO_LINK,
      MGM_HOME_COMPARTILHAMENTO_LINK_WPP,
      MGM_HOME_COMPARTILHAMENTO_TEXTO_WPP
    }
  } = useSelector((state) => state.campaignReducers.campaign)

  const appAccess = useSelector((state) => state.campaignReducers.appAccess)

  const handleOpen = () => {
    tracking({ event: 'boas_vindas_mgm_modal_open' })
    setOpenShare(true)
  }

  const handleOpenByHeader = () => {
    tracking({ event: 'boas_vindas_mgm_modal_open_by_header' })
    setOpenShare(true)
  }

  const handleClose = () => {
    tracking({ event: 'boas_vindas_mgm_modal_close' })
    setOpenShare(false)
  }

  return (
    <>
      {(createdThrough === 'backoffice' || (createdThrough === 'sheet' &&
        (MGM_HOME_COMPARTILHAMENTO_LINK && MGM_HOME_COMPARTILHAMENTO_LINK_WPP && MGM_HOME_COMPARTILHAMENTO_TEXTO_WPP)
      )) && (
        <>
          {
            showOnlyIcon ? (
              <Grid
                className={classes.SHARE_ICON}
                onClick={() => handleOpenByHeader(true)}
                role='button'
                aria-label='Compartilhar'
                tabIndex={0}
                onKeyDown={(e) => {
                  const isEnterOrSpace = e.key === 'Enter' || e.key === ' '
                  if (isEnterOrSpace) {
                    handleOpenByHeader(true)
                  }
                }}
              >
                <ShareIcon />
              </Grid>
            ) : (
              <Grow in>
                <Button
                  fullWidth
                  variant='outlined'
                  color='primary'
                  onClick={() => handleOpen(true)}
                  startIcon={<ShareIcon />}
                >
                  {shareButtonLabel}
                </Button>
              </Grow>
            )
          }
          <Dialog
            onClose={handleClose}
            open={openShare}
            closeAfterTransition
            fullWidth
            scroll='body'
            maxWidth='md'
            className={classes.DIALOG}
          >
            <Box p={isMobile ? 5 : 6}>
              <IconButton aria-label='close' className={classes.CLOSE} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <Grid container spacing={4} direction='column'>
                <Grid item>
                  <Grid container spacing={2} direction='column'>
                    <Grid item>
                      <Grid container spacing={4} direction='row' alignItems='center'>
                        {BOAS_VINDAS_IMAGEM_CAMPANHA && (
                          <Grid item xs={5}>
                            <Grow in>
                              <img
                                src={BOAS_VINDAS_IMAGEM_CAMPANHA}
                                alt={prms?.[0]?.title}
                                className={classes.IMG_FULL}
                              />
                            </Grow>
                          </Grid>
                        )}
                        <Grid item xs={7}>
                          <Typography variant='h5' color='inherit'>
                              Compartilhe a campanha com seus amigos
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={2} direction='row' alignItems='center'>
                        {prms?.[0]?.imageUrl && (
                          <Grid item xs={2}>
                            <Grow in>
                              <img src={prms?.[0]?.imageUrl} alt={prms?.[0]?.title} className={classes.IMG_FULL} />
                            </Grow>
                          </Grid>
                        )}
                        <Grid item xs={10}>
                          <Typography variant='body1' color='inherit'>
                            {prms?.[0]?.title}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Paper elevation={2}>
                    <CopyToClipboard
                      text={appAccess ? getOneLink(campaignId, 'copiarlink') : getLink(campaignId, 'copy')}
                      onCopy={() => {
                        tracking({ event: 'boas_vindas_mgm_copy_link' })
                        setCopy(true)
                      }}
                    >
                      <ShareButton icon={<FileCopyIcon />} active={copy}>
                        {copy ? 'Copiado!' : 'Copiar link'}
                      </ShareButton>
                    </CopyToClipboard>
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper elevation={2}>
                    <Link
                      to={{
                        pathname: WhatsappShare(
                            `Eu já garanti o meu mimo! Garanta o seu também: ${appAccess ? getOneLink(campaignId, 'whatsapp') : getLink(campaignId, 'wpp')}`
                        )
                      }}
                      target='_blank'
                      onClick={() => tracking({ event: 'boas_vindas_mgm_wpp_share' })}
                    >
                      <ShareButton icon={<WhatsAppIcon />}>WhatsApp</ShareButton>
                    </Link>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Dialog>
        </>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  DIALOG: {
    '& a': {
      textDecoration: 'none'
    }
  },
  CLOSE: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  IMG_FULL: {
    width: '100%',
    height: 'auto'
  },
  SHARE_ICON: {
    position: 'relative',
    zIndex: 9999,
    color: theme.palette.primary.main,
    background: theme.palette.background.default,
    borderRadius: 4,
    boxShadow: '0px 4px 24px -8px #00000066',
    width: theme.spacing(6),
    height: theme.spacing(6),
    cursor: 'pointer',
    padding: theme.spacing(1),
    transition: 'background 0.3s ease, color 0.3s ease',
    '&:focus': {
      outline: `2px solid ${theme.palette.primary.main}`
    }
  }
}))

export default ModalMgm
