export function setContentMode ({ contentMode } = {}) {
  return {
    type: 'config/SET_CONTENT_MODE',
    payload: { contentMode }
  }
}

export function setPreviewLayoutCampaign ({ layoutPreview } = {}) {
  return {
    type: 'config/SET_PREVIEW_LAYOUT_CAMPAIGN',
    payload: { layoutPreview }
  }
}
