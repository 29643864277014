import { all } from 'redux-saga/effects'

import campaign from './campaign/sagas'
import voucher from './voucher/sagas'
import reseller from './reseller/sagas'
import store from './store/sagas'
import raffle from './raffle/sagas'
import quiz from './quiz/sagas'
import geolocation from './geolocation/sagas'
import resetDate from './reset-date/sagas'
import bonusOffers from './bonus-offers/sagas'
import giftHub from './gift-hub/sagas'

export default function * rootSaga () {
  return yield all(
    [
      campaign,
      voucher,
      reseller,
      store,
      raffle,
      quiz,
      geolocation,
      resetDate,
      bonusOffers,
      giftHub
    ]
  )
}
