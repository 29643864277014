import createTheme from './createTheme'
import { fontTypes as Effra } from '~/assets/fonts/effra'
import { fontTypes as DomaineSansText } from '~/assets/fonts/domaine-sans-text'

const titleFonts = ['"Domaine Sans Text"', 'Times new roman', 'Serif'].join(
  ', '
)

const baseFont = ['"Effra"', 'Arial', 'sans-serif'].join(', ')

const primaryColor = '#1074AB'
const secondaryColor = '#3D9459'
const errorColor = '#DE1616'
const warningColor = '#FF9700'

export default createTheme({
  primaryColor,
  secondaryColor,
  errorColor,
  warningColor,
  titleFonts,
  baseFont,
  ExternalFonts: [...Effra, ...DomaineSansText]
})

export const layoutDefaultBOT = {
  BOAS_VINDAS_CONTEUDO: '',
  BOAS_VINDAS_CONTEUDO_BOTAO_PRINCIPAL: 'QUERO MEU MIMO!',
  BOAS_VINDAS_CONTEUDO_PRE_CAMPANHA: 'Cadastre-se até 18/nov, escolha a loja mais próxima e pegue seu Mini Lápis Labial Nude Malva Make B.!',
  BOAS_VINDAS_MENSAGEM_RODAPE: 'Já tem cadastro na campanha? Consulte <a href=\'/VERIFY_VOUCHER\'>AQUI</a> seu cupom!',
  BOAS_VINDAS_TITULO: '',
  CADASTRO_CONTEUDO_BOTAO_PRINCIPAL: 'PRÓXIMA',
  CADASTRO_CONTEUDO_MENSAGEM_CONSUMIDOR_ENCONTRADO: 'Encontramos informações que você já utilizou no Boticário! Confira se estão corretas e atualize se for necessário.',
  CADASTRO_INDICADO_CONTEUDO_BOTAO_PRINCIPAL: 'PRÓXIMA', // Funcionalidade Indicacao
  CADASTRO_INDICADO_TITULO: 'Agora, preencha os dados de quem vai receber o beijo virtual', // Funcionalidade Indicacao
  CADASTRO_TITULO: 'Preencha aqui os seus dados para garantir seu brinde',
  // COR_AVISEME: '#499D71',
  COR_BOXESCOLHA: '#FFFFFF',
  COR_CONTEUDO_PRIMARIA: '#515151',
  // COR_CONTRASTE_AVISEME: '#FFFFFF',
  COR_CONTRASTE_BOXESCOLHA: '#515151',
  COR_CONTRASTE_BRANCA: '#515151',
  COR_CONTRASTE_INFO_SEM_ESTOQUE: '#FFFFFF',
  COR_CONTRASTE_MSG_ERRO: '#FFFFFF',
  COR_CONTRASTE_PRIMARIA: '#FFFFFF',
  COR_CONTRASTE_SECUNDARIA: '#231f20',
  // COR_CONTRASTE_TERCIARIA: '#af4e52',
  COR_DESABILITADA_CONTRASTE_PRIMARIA: '#878787',
  COR_DESABILITADA_PRIMARIA: '#F1F1F1',
  COR_ETAPA_ATUAL: '#499D71',
  COR_FUNDO: '#FFFFFF',
  COR_INFO_SEM_ESTOQUE: '#000000',
  // COR_LOGO_MENU: '#499D71',
  // COR_LOGO_MENU_HOME: '#FFFFFF',
  COR_MSG_ERRO: '#FF0000',
  COR_PRIMARIA: '#499D71',
  COR_PROXIMA_ETAPA: '#F5F5F5',
  COR_SECUNDARIA: '#499D71',
  COR_TERCIARIA: '#499D71',
  CUPOM_DESCONTO_PREMIO: '', // Funcionalidade Sorteio
  // ESCOLHE_CANAL_CONTEUDO_BOTAO_PRINCIPAL: 'Escolher este canal',
  ESCOLHE_CANAL_CONTEUDO_ECOMM: 'Ir até o site www.boticario.com.br, fazer qualquer compra por R$189,90, e ganhar uma Mini Máscara Match de Nutrição Reparadora 50ml ', // Funcionalidade Ecomm
  // ESCOLHE_CANAL_CONTEUDO_LINK_BOTAO: 'www.boticario.com.br',
  ESCOLHE_CANAL_CONTEUDO_LOJA: 'Procurar pela loja mais próxima para resgatar meu brinde',
  ESCOLHE_CANAL_CONTEUDO_VD: 'Encontrar a revendedora mais próxima', // Funcionalidade Revendedora
  ESCOLHE_CANAL_CONTEUDO_WHATSAPP_STORE: 'Receber o brinde em casa ao fazer uma compra a partir de <strong>R$ 99,00</strong> pelo WhatsApp e ganhar uma Mini Máscara Match de Nutrição Reparadora 50ml ', // Funcionalidade Loja Digital
  ESCOLHE_CANAL_TITULO: 'Escolha como você pretende resgatar seu brinde',
  GALERIA_IFRAME_LINK: '****', // Funcionalidade Galeria
  LOJA_DATA_TITULO: 'Escolha a data para retirar o seu brinde na loja escolhida',
  // LOJA_ESCOLHE_TITULO: 'Pesquise a loja de acordo com a sua preferência',
  // LOJA_HORARIO_CONTEUDO_BOTAO: 'CONTINUAR',
  LOJA_HORARIO_TITULO: 'Para sua comodidade, escolha <b>DOIS HORÁRIOS</b> para buscar seu brinde:',
  LOJA_PESQUISA_TITULO: 'Pesquise a loja e escolha onde você deseja resgatar seu brinde',
  MENSAGEM_SUCESSO_CONSULTA_VOUCHER: '',
  // MGM_HOME_COMPARTILHAMENTO_LINK: '',
  // MGM_HOME_COMPARTILHAMENTO_LINK_WPP: '',
  // MGM_HOME_COMPARTILHAMENTO_TEXTO_WPP: 'Eu já garanti o meu mimo! Garanta o seu também: ',
  NOVO_CADASTRO_TELA_VOUCHER: 'Cadastrar outro selo', // Funcionalidade Sorteio
  QUIZ_COR_BOX_ESCOLHA: '#FFFFFF', // Funcionalidade Quiz
  QUIZ_COR_CONTRASTE_BOX_ESCOLHA: '#bd6735', // Funcionalidade Quiz
  QUIZ_COR_CONTRASTE_PRIMARIA: '#FFFFFF', // Funcionalidade Quiz
  QUIZ_COR_FUNDO: '#d09a6a', // Funcionalidade Quiz
  QUIZ_COR_ICONE: '#FFFFFF', // Funcionalidade Quiz
  QUIZ_COR_PRIMARIA: '#241e1d', // Funcionalidade Quiz
  QUIZ_COR_TEXTO: '#241e1d', // Funcionalidade Quiz
  REDIRECIONAR: '',
  RESULTADO_AVISO_CPF: 'ATENÇÃO! Apenas a pessoa titular deste CPF pode resgatar o brinde, leve seu documento com foto para apresentar na loja!',
  RESULTADO_CASHBACK_TITULO: 'Aqui está o valor que você pode utilizar nas lojas O Boticário ou pelo Whatsapp Oficial', // Funcionalidade Cashback
  // RESULTADO_CONTEUDO_BOTAO_CONCLUSAO: '',
  // RESULTADO_IMAGEM: '*não utilizado',
  RESULTADO_CONTEUDO_LINK_BOTAO: 'www.boticario.com.br',
  RESULTADO_IMAGEM_PREMIO: '', // Funcionalidade Sorteio
  RESULTADO_INDICACAO_AVISO: '*não utilizado', // Funcionalidade Indicacao
  RESULTADO_INDICACAO_AVISO_ECOMM: '', // Funcionalidade Indicacao
  RESULTADO_INDICACAO_AVISO_LOJA: '', // Funcionalidade Indicacao
  RESULTADO_INDICACAO_AVISO_VD: '', // Funcionalidade Indicacao
  RESULTADO_INDICACAO_TEXTO_COMPARTILHAR: '*não utilizado', // Funcionalidade Indicacao
  RESULTADO_INDICACAO_TITULO_ECOMM: '', // Funcionalidade Indicacao
  RESULTADO_INDICACAO_TITULO_LOJA: '', // Funcionalidade Indicacao
  RESULTADO_INDICACAO_TITULO_VD: '', // Funcionalidade Indicacao
  RESULTADO_INDICACAO_TITULO: '*não utilizado', // Funcionalidade Indicacao
  // RESULTADO_INSTRUCOES_LOJA_FECHADA: '*não utilizado',
  // RESULTADO_INSTRUCOES_REVENDEDORA: '*não utilizado',
  // RESULTADO_REGRAS: '*não utilizado',
  // RESULTADO_SUBTITULO_LOJA_ABERTA: '*não utilizado',
  RESULTADO_TARJA_FUNDO: '#499D71',
  RESULTADO_TARJA_TEXTO: '#FFFFFF',
  RESULTADO_TEXTO_CONTATO: 'Atente-se aos seus meios de comunicação (telefone, WhatsApp e e-mail), que entraremos em contato com você para envio do seu prêmio!', // Funcionalidade Sorteio
  RESULTADO_TITULO: 'Pronto!',
  RESULTADO_TITULO_MODALIDADE_SORTEIO: 'Coloque na sua agenda o dia do sorteio e boa sorte :D', // Funcionalidade Sorteio
  RESULTADO_VOUCHER_TITULO_SELO_PREMIADO: 'Selo premiado', // Funcionalidade Sorteio
  RESULTADO_WHATSAPP_INSTRUCAO: 'Chame o Boti no WhatsApp pra ganhar sua Mini Máscara Match de Nutrição Reparadora 50ml em compras a partir de R$ 99,00. Você compra com mais conforto e ainda recebe em casa!', // Funcionalidade Loja Digital
  RESULTADO_WHATSAPP_MENSAGEM_CLICK_TO_CHAT: 'Quero fazer uma compra a partir de R$99,00 e receber a Mini Máscara Match de Nutrição Reparadora 50ml em casa! ', // Funcionalidade Loja Digital
  RESULTADO_WHATSAPP_MENSAGEM_COMPARTILHAR: '****', // MGM Indicação e Unique Voucher Code
  RESULTADO_WHATSAPP_MENSAGEM_VALOR_MINIMO: 'Para compras a partir de R$ 99', // Funcionalidade Loja Digital
  // REVENDEDORA_ESCOLHE_CONTEUDO_BOTAO_PRINCIPAL: '*não utilizado',
  REVENDEDORA_ESCOLHE_TITULO: '*não utilizado', // Funcionalidade Revendedora
  TEXTO_MENSAGEM_LISTA_ACESSO: 'Período de agendamento exclusivo para clientes Clube Viva que fizeram o pré-cadastro, em breve você poderá se cadastrar na campanha!', // Funcionalidade acesso antecipado
  SENHA_DESCRICAO_IMG: '', // Funcionalidade senha
  SENHA_DESCRICAO_TEXTO: '', // Funcionalidade senha
  SENHA_TEXTO: '', // Funcionalidade senha
  // UPLOAD_AVISO: 'Precisamos da sua permissão para acessar a câmera',
  // UPLOAD_TEXTO_BOTAO_CAMERA: 'TIRAR FOTO',
  // UPLOAD_TEXTO_BOTAO_CONCLUSAO_OUTRA: 'TIRAR OUTRA FOTO',
  // UPLOAD_TEXTO_BOTAO_GALERIA: 'CARREGAR DA GALERIA',
  UPLOAD_TITULO: 'Chegou a hora de escolher a sua foto' // Funcionalidade Upload
  // UPLOAD_TITULO_CONCLUSAO: 'E aí, ficou bonitx?',
  // VERIFY_VOUCHER_CONTEUDO_BOTAO: 'CONSULTAR CUPOM',
  // VERIFY_VOUCHER_CONTEUDO_MENSAGEM: 'Encontramos um cupom cadastrado no CPF informado. Basta você clicar na opção abaixo para visualizá-lo'
}
