export const leaveOnlyNumbers = (string = '') => string.replace(/\D+/g, '')

export const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`).replace('_', '')

export const textToSnakeCase = (str) => {
  return String(str || '').normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    .replace(/([^\w]+|\s+)/g, '_')
    .replace(/__+/g, '_')
    .replace(/(^-+|-+$)/, '')
    .toLowerCase()
}

export const stringSearchPrepare = (str) => {
  return String(str || '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
}

export const isString = (string) => typeof string === 'string' || string instanceof String

export const isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return re.test(String(email).toLowerCase()) || false
}

export const isValidCPF = (cpf) => {
  if (typeof cpf !== 'string') {
    return false
  }

  cpf = cpf.replace(/[\s.-]*/igm, '')

  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false
  }

  let sum = 0
  let rest

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
  }

  rest = (sum * 10) % 11

  if ((rest === 10) || (rest === 11)) {
    rest = 0
  }

  if (rest !== parseInt(cpf.substring(9, 10))) {
    return false
  }

  sum = 0

  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i)
  }

  rest = (sum * 10) % 11

  if ((rest === 10) || (rest === 11)) {
    rest = 0
  }

  if (rest !== parseInt(cpf.substring(10, 11))) {
    return false
  }

  return true
}

export const isOdd = (document) => ((Number(String(document).slice(-1)) % 2) > 0)

export const base64ToFile = (base64File, filename) => {
  const [posMime, posString] = base64File.split(',')
  const type = posMime.match(/:(.*?);/)[1]
  const bstr = atob(posString)

  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type })
}

export const htmlClean = (html) => {
  return new DOMParser().parseFromString(html, 'text/html').body.innerHTML
}

export const removeAccents = (text) => {
  return text?.toString().toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const isEqualIgnoreCase = (a, b) => String(a).toUpperCase() === String(b).toUpperCase()
