import { takeLatest, put, all, call } from 'redux-saga/effects'

import api from '~/services/api'

import {
  updateResetDate
} from './actions'

export function * sendEmail ({ payload }) {
  const errorMessage = 'Ocorreu um erro ao processar a requisição.'

  try {
    const { cpf, campaignId } = payload

    if (!cpf && !campaignId) {
      throw new Error('Ocorreu um erro ao processar a requisição')
    }

    const url = `/campaign/${campaignId}/resetdata`
    const response = yield call(
      api.post,
      url,
      {
        consumerCpf: cpf
      }
    )

    if (response.data?.success) {
      if (response.data && response.data.data.length > 0) {
        yield put(updateResetDate({ email: response.data.data[0].email, error: false }))
      } else {
        yield put(updateResetDate({ error: errorMessage }))
      }
    } else {
      yield put(updateResetDate({ error: errorMessage }))
    }
  } catch (error) {
    yield put(updateResetDate({ error: errorMessage }))
  }
}

export default all([
  takeLatest('resetDate/SET_RESET_DATE', sendEmail)
])
