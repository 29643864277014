import produce from 'immer'
import * as ACTs from './actions'

export const Types = {}

Object.values(ACTs).forEach(fn => {
  const type = fn().type

  Types[type.split('/').pop()] = type
})

const INITIAL_STATE = {
  loadings: {
    getQuiz: false
  },
  errors: {
    getQuiz: false
  },
  attempts: {
    getQuiz: 0
  },
  quiz: [],
  quizResult: {},
  quizStep: 0,
  skipResult: false,
  previousQuizSteps: []
}

export default function quizReducers (state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.GET_QUIZ: {
        draft.loadings.getQuiz = true
        draft.errors.getQuiz = false
        draft.attempts.getQuiz = 0
        break
      }
      case Types.UPDATE_QUIZ: {
        draft.loadings.getQuiz = false
        draft.attempts.getQuiz = 1
        draft.errors.getQuiz = action.payload?.error || false

        const quiz = action?.payload?.quiz || false

        if (quiz) {
          if (typeof quiz?.code === 'number') {
            draft.quiz[quiz?.code] = quiz
            draft.quizStep = quiz?.code
          }

          if (quiz?.contentType === 'noResult') {
            draft.skipResult = true
          }
        }
        break
      }
      case Types.SET_QUIZ: {
        draft.loadings.getQuiz = true
        draft.errors.getQuiz = action.payload?.error || false
        break
      }
      case Types.SET_QUIZ_RESULT: {
        draft.quizResult = action.payload?.quizResult || {}
        break
      }
      case Types.SET_QUIZ_STEP: {
        draft.quizStep = action.payload?.quizStep || 0
        break
      }
      case Types.SET_PREVIOUS_QUIZ_STEP: {
        draft.previousQuizSteps = action.payload?.previousQuizSteps || []
        break
      }
      case Types.RESET_QUIZ: {
        draft.errors.getQuiz = false
        draft.attempts.getQuiz = 0
        break
      }
      default:
    }
  })
}
