import React, { lazy } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'

import { Alert, AlertTitle } from '@material-ui/lab'

import Header from '~/components/header'
import configs from '~/env.json'
import { BRANCH_TYPES } from '~/helpers/constants'

const BoasVindas = lazy(() => import('./BOAS_VINDAS'))
const BoasVindasHub = lazy(() => import('./BOAS_VINDAS_HUB'))
const Cadastro = lazy(() => import('./CADASTRO'))
const ResetData = lazy(() => import('./RESET_DATA'))
const CadastroIndicado = lazy(() => import('./CADASTRO_INDICADO'))
const Upload = lazy(() => import('./UPLOAD'))
const EscolheCanal = lazy(() => import('./ESCOLHE_CANAL'))
const EscolheRevendedoraLoja = lazy(() => import('./ESCOLHE_REVENDEDORA_LOJA'))
const EscolheDataResgate = lazy(() => import('./ESCOLHE_DATA_RESGATE'))
const EscolheHorarioResgate = lazy(() => import('./ESCOLHE_HORARIO_RESGATE'))
const Quiz = lazy(() => import('./QUIZ'))
const Resultado = lazy(() => import('./RESULTADO'))
const ShowVoucher = lazy(() => import('./SHOW_VOUCHER'))
const VerifyVoucher = lazy(() => import('./VERIFY_VOUCHER'))
const Products = lazy(() => import('./PRODUCTS'))
const Galeria = lazy(() => import('./GALERIA'))
const OpcoesHub = lazy(() => import('./OPCOES_HUB'))
const ResultadoSorteio = lazy(() => import('./RESULTADO_SORTEIO'))
const Senha = lazy(() => import('./SENHA'))
const PulpoAR = lazy(() => import('./PULPOAR'))
const ErroAcesso = lazy(() => import('./ERRO_ACESSO'))

export default ({ campaignId, slug }) => {
  const classes = useStyles()
  const history = useHistory()

  const { steps, campaignId: campaignState, modality } = useSelector((state) => state.campaignReducers.campaign)

  if (!campaignState) {
    if (configs.REACT_APP_BRANCH === BRANCH_TYPES.AUS.acron) {
      history.push('/verao-o-ano-todo/BOAS_VINDAS')
    } else {
      history.push(campaignId ? `/${campaignId}/ERRO_ACESSO` : '/CAMPANHA_INVALIDA/ERRO_ACESSO')
    }
    window.location.reload()
  }

  const StepVerify = parseInt(steps?.filter((step) => step?.view === slug?.trim())?.shift()?.step)

  const LayoutDefault = ({ component, disableHeader = false, needPersistedVoucher = false }) => {
    const classes = useStyles()

    const voucher = useSelector((state) => state.voucherReducers.voucher)

    if (needPersistedVoucher && Object.entries(voucher).length === 0) {
      return <Redirect to={`/${campaignId}/BOAS_VINDAS`} />
    }

    return (
      <div className={classes.PAGE_CONTAINER}>
        {disableHeader === false && <Header border={StepVerify === 1} />}
        {component}
      </div>
    )
  }

  const DynamicPages = {
    BOAS_VINDAS: (props) => (
      <LayoutDefault
        component={modality === 'HUB_DO_PRESENTE' ? <BoasVindasHub {...props} /> : <BoasVindas {...props} />}
      />
    ),
    CADASTRO: (props) => <LayoutDefault component={<Cadastro {...props} />} />,
    RESET_DATA: (props) => <LayoutDefault component={<ResetData {...props} />} />,
    CADASTRO_INDICADO: (props) => <LayoutDefault component={<CadastroIndicado {...props} />} needPersistedVoucher />,
    UPLOAD: (props) => <LayoutDefault component={<Upload {...props} />} needPersistedVoucher />,
    ESCOLHE_CANAL: (props) => <LayoutDefault component={<EscolheCanal {...props} />} needPersistedVoucher />,
    ESCOLHE_REVENDEDORA_LOJA: (props) => (
      <LayoutDefault component={<EscolheRevendedoraLoja {...props} />} needPersistedVoucher />
    ),
    ESCOLHE_DATA_RESGATE: (props) => (
      <LayoutDefault component={<EscolheDataResgate {...props} />} needPersistedVoucher />
    ),
    ESCOLHE_HORARIO_RESGATE: (props) => (
      <LayoutDefault component={<EscolheHorarioResgate {...props} />} needPersistedVoucher />
    ),
    QUIZ: (props) => <LayoutDefault component={<Quiz {...props} />} disableHeader needPersistedVoucher />,
    RESULTADO: (props) => <LayoutDefault component={<Resultado {...props} />} needPersistedVoucher />,
    SHOW_VOUCHER: (props) => <LayoutDefault component={<ShowVoucher {...props} />} needPersistedVoucher />,
    VERIFY_VOUCHER: (props) => <LayoutDefault component={<VerifyVoucher {...props} />} />,
    PRODUCTS: (props) => <LayoutDefault component={<Products {...props} />} />,
    GALERIA: (props) => <LayoutDefault component={<Galeria {...props} />} />,
    RESULTADO_SORTEIO: (props) => <LayoutDefault component={<ResultadoSorteio {...props} />} />,
    SENHA: (props) => <LayoutDefault component={<Senha {...props} />} needPersistedVoucher />,
    OPCOES_HUB: (props) => <LayoutDefault component={<OpcoesHub {...props} />} needPersistedVoucher />,
    PULPOAR: (props) => <LayoutDefault component={<PulpoAR {...props} />} />,
    ERRO_ACESSO: (props) => <LayoutDefault component={<ErroAcesso {...props} />} />
  }

  const NOT_FOUND = () => {
    return (
      <div className={classes.PAGE_NOT_FOUND}>
        <Alert variant='filled' severity='error'>
          <AlertTitle>
            <FormattedMessage id='Ocorreu um erro' />
          </AlertTitle>
          <FormattedMessage id='O layout não foi encontrado' />
        </Alert>
      </div>
    )
  }

  const DynamicComponent = DynamicPages[slug?.trim()] || NOT_FOUND
  const DynamicStep = StepVerify > 0 ? StepVerify - 1 : 0

  return [DynamicComponent, DynamicStep]
}

const useStyles = makeStyles((theme) => ({
  PAGE_CONTAINER: {
    padding: theme.spacing(10, 5, 5, 5),
    width: '100%',
    minHeight: window.innerHeight,
    display: 'flex'
  },
  PAGE_NOT_FOUND: {
    backgroundColor: theme.palette.background.default,
    minHeight: window.innerHeight,
    padding: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))
