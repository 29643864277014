import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Box, Typography } from '@material-ui/core'

const Button = ({ icon, children, onClick = () => {}, active } = {}) => {
  const classes = useStyles()

  return (
    <Box
      gridGap={16}
      padding='12px 16px'
      display='flex'
      onClick={onClick}
      alignItems='center'
      className={active ? classes.ACTIVE : classes.DEFAULT}
      justifyContent='flex-start'
    >
      {!!icon && icon}
      {children && (
        <Typography variant='body1' color='inherit'>
          {children}
        </Typography>
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  DEFAULT: {
    color: theme.palette.primary.main,
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.secondary
    }
  },
  ACTIVE: {
    background: theme.palette.primary.main,
    color: theme.themeCustom.COR_CONTRASTE_PRIMARIA,
    borderRadius: 6,
    '& .MuiSvgIcon-root': {
      color: theme.themeCustom.COR_CONTRASTE_PRIMARIA
    }
  }
}))

export default Button
