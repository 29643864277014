import produce from 'immer'

const INITIAL_STATE = {
  isLoading: false
}

export default function fullpageLoaderReducers (state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case 'fullpageLoader/SET_GLOBAL_LOADING': {
        draft.isLoading = action.payload.isLoading
        break
      }

      default:
    }
  })
}
