export function searchResellersNearby ({ campaignId, geolocation } = {}) {
  return {
    type: 'reseller/SEARCH_RESELLERS_NEARBY',
    payload: { campaignId, geolocation }
  }
}

export function updateResellersNearby ({ resellers = [], error } = {}) {
  return {
    type: 'reseller/UPDATE_RESELLERS_NEARBY',
    payload: { resellers, error }
  }
}

export function pickReseller ({ reseller = {} } = {}) {
  return {
    type: 'reseller/PICK_RESELLER',
    payload: { reseller }
  }
}

export function bookingVoucher ({ campaignId, consumerCpf, voucherCode, resellerCode } = {}) {
  return {
    type: 'reseller/BOOKING_VOUCHER',
    payload: { campaignId, consumerCpf, voucherCode, resellerCode }
  }
}

export function updateBookingVoucher ({ error } = {}) {
  return {
    type: 'reseller/UPDATE_BOOKING_VOUCHER',
    payload: { error }
  }
}
