import mixTheme from './mixTheme'
import themeGB from './GB'
import themeBOT, { layoutDefaultBOT } from './BOT'
import themeQDB, { layoutDefaultQDB } from './QDB'
import themeVULT, { layoutDefaultVULT } from './VULT'
import themeAUS, { layoutDefaultAUS } from './AUS'

export const GB = themeGB
export const BOT = themeBOT
export const QDB = themeQDB
export const VULT = themeVULT
export const AUS = themeAUS

export default mixTheme

export const defaultLayout = (theme) => {
  const defaults = {
    BOT: layoutDefaultBOT,
    QDB: layoutDefaultQDB,
    VULT: layoutDefaultVULT,
    AUS: layoutDefaultAUS
  }

  return defaults?.[theme] || layoutDefaultBOT
}
