import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

import {
  Typography
} from '@material-ui/core'

const FullPageLoader = (props) => {
  const { text } = props

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress size={30} />
      {text && <Typography variant='caption' className={classes.text}>{text}</Typography>}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: window.innerHeight,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    marginTop: theme.spacing(2)
  }
}))

FullPageLoader.propTypes = {
  text: PropTypes.string
}

FullPageLoader.defaultProps = {
  text: ''
}

export default FullPageLoader
