import produce from 'immer'

import * as ACTs from './actions'

export const Types = {}

Object.values(ACTs).forEach(fn => {
  const type = fn().type

  Types[type.split('/').pop()] = type
})

const INITIAL_STATE = {
  width: window.innerWidth,
  contentMode: '',
  previewLayoutCampaign: {}
}

export default function configReducers (state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Types.SET_CONTENT_MODE: {
        draft.contentMode = action.payload.contentMode
        break
      }
      case Types.SET_PREVIEW_LAYOUT_CAMPAIGN: {
        draft.previewLayoutCampaign = action.payload.layoutPreview
        break
      }
      default:
    }
  })
}
