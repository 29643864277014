import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { isEmbedMode } from '~/helpers/contentMode'
import configs from '~/env.json'

export default () => {
  const classes = useStyles()

  const contentMode = useSelector(state => state.configReducers.contentMode)

  useEffect(() => {
    window.parent.postMessage(JSON.stringify({ iframeReady: true }), configs?.REACT_BACKOFFICE_MARKETING)
  }, [])

  return (
    <div className={classes.PREVIEW_CONTAINER}>Preview mode {isEmbedMode(contentMode)}</div>
  )
}

const useStyles = makeStyles((theme) => ({
  PREVIEW_CONTAINER: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: 10,
    position: 'fixed',
    right: 10,
    bottom: 10,
    zIndex: 1,
    borderRadius: 10
  }
}))
