import Regular from './MaisonNeueExtendedWEB-Book.woff2'
import Bold from './MaisonNeueExtendedWEB-ExtraBold.woff2'

const fontName = 'Maison Neue Extended'

export const fontTypes = []

fontTypes.push({
  fontFamily: `'${fontName}'`,
  src: `local('${fontName}'), url(${Regular}) format('woff2')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontWeight: 600,
  src: `local('${fontName}'), url(${Regular}) format('woff2')`
})

fontTypes.push({
  fontFamily: `'${fontName}'`,
  fontWeight: 700,
  src: `local('${fontName}'), url(${Bold}) format('woff2')`
})
