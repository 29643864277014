import configs from '~/env.json'
import React, { lazy, useEffect } from 'react'
import { Switch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import TagManager from 'react-gtm-module'
import { hotjar } from 'react-hotjar'
import PreviewMode from '~/components/preview-mode'

import Route from './Route'

import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import mixTheme, * as Themes from '~/assets/styles'
import Dynamic from './Dynamic'

import ErroAcesso from '~/pages/dynamic/ERRO_ACESSO'

import { isEmbedMode } from '~/helpers/contentMode'

import { setPreviewLayoutCampaign } from '~/store/modules/config/actions'

const DownloadApp = lazy(() => import('~/pages/download-app'))
const Initial = lazy(() => import('~/pages/initial'))

export default () => {
  const dispatch = useDispatch()

  const appAccess = useSelector((state) => state.campaignReducers.appAccess)
  const blockFront = useSelector((state) => state.campaignReducers.blockFront)
  const contentMode = useSelector((state) => state.configReducers.contentMode)
  const previewLayoutCampaign = useSelector((state) => state.configReducers.previewLayoutCampaign)

  const { branch, campaignId, campaignName, layout } = useSelector((state) => state.campaignReducers.campaign)

  useEffect(() => {
    if (configs?.NODE_ENV === 'production' && configs?.REACT_APP_GTM !== '#' && !isEmbedMode(contentMode)) {
      const tagManagerArgs = {
        gtmId: configs.REACT_APP_GTM
      }

      TagManager.initialize(tagManagerArgs)
    }

    if (configs?.NODE_ENV === 'production' && configs?.REACT_APP_HOTJAR !== '#' && !isEmbedMode(contentMode)) {
      const [hjid, hjsv] = configs.REACT_APP_HOTJAR.split('::')

      hotjar.initialize(hjid, hjsv)
    }
  }, [contentMode])

  useEffect(() => {
    if (campaignName) {
      document.querySelector('head title').text = campaignName
    }

    if (layout?.COR_FUNDO) {
      document.querySelector('head [name="theme-color"]').content = layout.COR_FUNDO
    }
  }, [campaignId, campaignName, layout, previewLayoutCampaign])

  useEffect(() => {
    window.addEventListener(
      'message',
      (event) => {
        if (event.data == null || event.origin !== configs?.REACT_BACKOFFICE_MARKETING) {
          return
        }

        const { layoutObject } = JSON.parse(event.data)

        dispatch(setPreviewLayoutCampaign({ layoutPreview: layoutObject }))
      },
      false
    )
  }, [dispatch])

  const hasPreviewLayoutCampaign = (previewLayoutCampaign && Object.keys(previewLayoutCampaign).length) || false

  const themeOptions = getThemeOptions(hasPreviewLayoutCampaign, branch, previewLayoutCampaign, layout)

  if (campaignId && layout?.REDIRECIONAR) {
    document.location.href = layout.REDIRECIONAR
  }

  return (
    <ThemeProvider theme={themeOptions}>
      <CssBaseline />

      {isEmbedMode(contentMode) && (
        <>
          <PreviewMode />
        </>
      )}
      {!campaignId ? (
        <Switch>
          <Route path='/' exact component={Dynamic} />
          <Route path='/:campaignId/ERRO_ACESSO' exact component={ErroAcesso} />
          <Route path='/:campaignId' component={Initial} />
        </Switch>
      ) : (
        <Switch>
          <Route path='/:campaignId' exact component={Initial} />
          <Route path='/:campaignId/:slug' component={blockFront && !appAccess ? DownloadApp : Dynamic} />
        </Switch>
      )}
    </ThemeProvider>
  )
}

function getThemeOptions (hasPreviewLayoutCampaign, branch, previewLayoutCampaign, layout) {
  const defaulBranch = configs?.REACT_APP_BRANCH || 'BOT'

  if (hasPreviewLayoutCampaign) {
    return mixTheme(Themes[branch || defaulBranch], previewLayoutCampaign)
  }

  if (layout) {
    return mixTheme(Themes[branch || defaulBranch], layout)
  }

  return mixTheme(Themes[defaulBranch], Themes.defaultLayout(defaulBranch))
}
