import configs from '~/env.json'
import axios from 'axios'
import { isEmbedMode } from '~/helpers/contentMode'
import { hasMocky } from '~/helpers/mocks'
import { useHandleErrorToken } from '~/helpers/handleErrorToken'

const api = axios.create({
  baseURL: (configs?.NODE_ENV === 'production' ? configs?.REACT_APP_API : 'https://hacaodefluxo-api.grupoboticario.digital')
})

api.interceptors.response.use(response => (response), error => {
  console.error(JSON.stringify(error.response?.data))

  const originalRequest = error.config
  if ([401, 403].includes(error.response?.status) && !originalRequest._retry) {
    const refreshToken = localStorage.getItem('refreshToken')
    return api.post('/refreshToken', { refreshToken })
      .then((newResp) => {
        const { accessToken, refreshToken } = newResp.data.data[0]
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('refreshToken', refreshToken)

        originalRequest.headers.Authorization = `Bearer ${accessToken}`
        originalRequest._retry = true
        return axios(originalRequest)
      })
      .catch(() => {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')

        useHandleErrorToken()

        return {
          status: 200,
          data: {
            errors: ['Sua sessão expirou. Você será redirecionado para a tela inicial.'],
            message: 'Erro com a identificação do usuário',
            success: false
          }
        }
      })
  }
})

api.interceptors.request.use((conf) => {
  const contentModeCampaign = localStorage.getItem('contentMode')

  if (isEmbedMode(contentModeCampaign)) {
    const urlMocky = hasMocky(conf)
    if (urlMocky) {
      conf.baseURL = '/'
      conf.url = `mocks/${urlMocky}`
      conf.method = 'GET'
    }
  }

  return conf
})

api.interceptors.request.use((conf) => {
  const search = window.location.search
  const params = new URLSearchParams(search)
  const accessToken = params.get('accessToken') || localStorage.getItem('accessToken')

  if (accessToken) {
    conf.headers.Authorization = `Bearer ${accessToken}`
  }
  return conf
})

export default api
