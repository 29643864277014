export function getCampaignConfig ({ campaignId, appAccess } = {}) {
  return {
    type: 'campaign/GET_CAMPAIGN_CONFIG',
    payload: { campaignId, appAccess }
  }
}

export function updateCampaignConfig ({ campaign = {}, error } = {}) {
  return {
    type: 'campaign/UPDATE_CAMPAIGN_CONFIG',
    payload: { campaign, error }
  }
}
