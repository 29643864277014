import ExtraBold from './Larken-ExtraBold.woff'

const fontName = 'Larken'

export const fontTypes = []

fontTypes.push({
  fontFamily: `'${fontName}'`,
  src: `local('${fontName}'), url(${ExtraBold}) format('woff')`
})
