export function getVoucher ({ campaignId, consumerCpf, consumerBirthday, captchaToken, isReadOnly = false, comingPath } = {}) {
  return {
    type: 'voucher/GET_VOUCHER',
    payload: { campaignId, consumerCpf, consumerBirthday, captchaToken, isReadOnly, comingPath }
  }
}

export function updateVoucherVerify ({ voucher, error } = {}) {
  return {
    type: 'voucher/UPDATE_VOUCHER_VERIFY',
    payload: { voucher, error }
  }
}

export function setVoucher ({ campaignId, consumer = {}, captchaToken, step, _redirect, _condition } = {}) {
  return {
    type: 'voucher/SET_VOUCHER',
    payload: { campaignId, consumer, captchaToken, step, _redirect, _condition }
  }
}

export function updateVoucher ({ voucher, error } = {}) {
  return {
    type: 'voucher/UPDATE_VOUCHER',
    payload: { voucher, error }
  }
}

export function setChannel ({ campaignId, consumerCpf, channelType, _redirect, _condition } = {}) {
  return {
    type: 'voucher/SET_CHANNEL',
    payload: { campaignId, consumerCpf, channelType, _redirect, _condition }
  }
}

export function updateChannel ({ channel, error } = {}) {
  return {
    type: 'voucher/UPDATE_CHANNEL',
    payload: { channel, error }
  }
}

export function updateConsumer ({ field, value } = {}) {
  return {
    type: 'voucher/UPDATE_CONSUMER',
    payload: { field, value }
  }
}

export function resetVoucher () {
  return {
    type: 'voucher/RESET_VOUCHER'
  }
}

export function updateEligibleConsumer ({ eligibleConsumer } = {}) {
  return {
    type: 'voucher/ELIGIBLE_CONSUMER',
    payload: { eligibleConsumer }
  }
}
