import { useHistory, useLocation } from 'react-router-dom'

export const useHandleErrorToken = () => {
  const location = useLocation()
  const history = useHistory()

  const handleErrorToken = () => {
    const [, campaignId] = location.pathname.split('/')
    history.push(`/${campaignId}`)
  }

  return handleErrorToken
}
